module.exports = {
    ENV: 'prod',
    APP_NAME: 'metropolis',
    END_POINT: 'https://ssm.services.apihf.com',
    API_KEY: 'EvmK7Oxa7I5LHo0te5l1Y1Dqk3ugrovw5Nci5Ovv',
    BRAND_KEY: '0c7219a8-ce8e-4430-bf3f-ca9e22b7c6b2',
    CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
    USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
    HEADER_MESSAGE_SIZE: 41,
    AGENTS_GROUP: 'METROPOLIS_AGENTS',
}